import http from "@/api/http.js";

function listByStartTimeAndEndTime(startTime, endTime) {
  return http({
    url: "/HmsDisinfection/ListByStartTimeAndEndTime",
    method: "get",
    params: {
      startTime: startTime,
      endTime: endTime,
    },
  });
}

export default {
  listByStartTimeAndEndTime,
};
